<template>
  <div class="main-box">
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addMenu()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delMenu()">删除</el-button>
    </div>
    <el-tabs v-model="menuId" @tab-click="changeMenuType">
      <el-tab-pane label="书库商城分类" name="2"></el-tab-pane>
      <el-tab-pane label="单本出租绘本分类" name="1"></el-tab-pane>
      <el-tab-pane label="兑换商品分类" name="3"></el-tab-pane>
      <el-tab-pane label="书单分类" name="4"></el-tab-pane>
    </el-tabs>
    <el-table
      :data="tableData"
      ref="rfTable"
      border
      row-key="id"
      style="width: 100%;"
      size="mini"
      :default-expand-all="true"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
        type="selection"
        align="center"
        width="55">
      </el-table-column>
      <el-table-column
              prop="title"
              label="名称">
      </el-table-column>
      <el-table-column
              prop="pic"
              label="图标">
        <template slot-scope="scope">
          <el-avatar shape="square" :size="30" :src="scope.row.pic" v-if="scope.row.pic"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column
              prop="ordering"
              label="排序"
              align="center"
              width="120">
      </el-table-column>
      <el-table-column
              prop="create_time"
              label="添加时间"
              align="center"
              width="140">
      </el-table-column>
      <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="editMenu(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="menuTitle" :visible.sync="dialogFormVisible" custom-class="dialog">
      <el-form :model="form" size="small">
        <el-form-item label="分类名称" :label-width="formLabelWidth">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="父级分类" :label-width="formLabelWidth" v-if="!form.id || form.pid">
          <el-select v-model="form.pid" placeholder="请选择分类" clearable>
            <el-option :label="item.title" :value="item.id" v-bind:key="index" v-for="(item, index) in tableData"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图标" :label-width="formLabelWidth">
          <SelectImgs :selectNum="1" :selectData="pic" :selectFunc="picChoose"></SelectImgs>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.ordering" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="saveMenu" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
export default {
  components: {
    SelectImgs
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      menuTitle: '添加分类',
      dialogFormVisible: false,
      pic: [],
      form: {
        id: 0,
        pid: 0,
        title: '',
        pic: '',
        ordering: ''
      },
      formLabelWidth: '120px',
      tableData: [],
      menuId: '2'
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    changeMenuType(e) {
      this.getList()
    },
    picChoose(item) {
      this.pic = [item.pic]
      this.form.pic = item.pic
    },
    getList() {
      var that = this
      that.showLoading()
      this.$api.goods.menuIndex({type: this.menuId}, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    editMenu(item) {
      var that = this
      this.$api.goods.menuDetail({id: item.id},function (res) {
        if(res.errcode == 0) {
          that.form = res.data
          if(that.form.pid == 0) {
            that.form.pid = ''
          }
          that.pic = [that.form.pic]
          that.dialogFormVisible = true
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addMenu() {
      this.form.id = 0
      this.form.pid = ''
      this.form.title = ''
      this.form.pic = ''
      this.pic = []
      this.form.ordering = ''
      this.dialogFormVisible = true
    },
    saveMenu() {
      var that = this
      var param = {
        id: that.form.id,
        pid: that.form.pid,
        title: that.form.title,
        pic: that.form.pic,
        ordering: that.form.ordering,
        type: this.menuId
      }
      if(this.form.id == 0) {
        this.$api.goods.menuAdd(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      } else {
        this.$api.goods.menuEdit(param,function (res) {
          if(res.errcode == 0) {
            that.dialogFormVisible = false
            that.getList()
            that.success(res.errmsg)
          } else {
            that.fail(res.errmsg)
          }
        })
      }
    },
    delMenu() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if(sel.length<1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.menuDelete({ids: ids},function (res) {
          if(res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    }
  }
};
</script>
<style scoped>
</style>
